<template>
  <div :class="['card', `col-${item.col}`, {big: item.big}]" :style="{backgroundColor: item.bgColor, color: item.textColor}">
    <p class="card__text" v-html="item.topText"></p>
    <div>
      <p class="card__name">{{ item.name }}</p>
      <div>
        <span class="card__active-type card__text">
          {{activeType}}:
        </span>
        <span class="card__color card__text">
          {{item.color}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    activeType: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 256px;
  height: 256px;
  border-radius: 4px;
  background-color: #fff;
  padding: 24px 24px 32px;

  &__text {
    font-size: 13px;
    line-height: 18px;
    font-weight: 200;
    letter-spacing: 0.02em;
    opacity: 0.6;
  }

  &__name {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.1px;
    margin-bottom: 5px;
  }

  &__active-type {
    text-transform: capitalize;
  }

  &__color {
    opacity: 1;
    text-transform: uppercase;
  }

  &.big {
    height: 544px;
    //justify-content: normal;

    .card {
      &__text {
        margin-bottom: 92px;
      }

      &__name {
        //margin-bottom: 296px;
      }
    }
  }
}

.col {
  &-2 {
    width: 544px;
  }
}

@media screen and (max-width: 1070px) {
  .card {
    width: 100%;
    max-height: 256px;
    &.big {
      //justify-content: space-between;

      .card {
        &__text {
          margin-bottom: 0;
        }

        &__name {
          margin-bottom: 5px;
        }
      }
    }
  }
}

</style>