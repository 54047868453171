<template>
  <div class="page page__content-wrapper">
    <div class="buttons-block" :class="{sticky, 'top-80': topIndent}">
      <div class="buttons-block__wrapper">
        <button-toggle :is-active="activeType==='Hex'" @click="activeType = 'Hex'">Hex</button-toggle>
        <button-toggle :is-active="activeType==='RGB'" @click="activeType = 'RGB'">RGB</button-toggle>
        <button-toggle :is-active="activeType==='CMYK'" @click="activeType = 'CMYK'">CMYK</button-toggle>
        <button-toggle :is-active="activeType==='Pantone'" @click="activeType = 'Pantone'">Pantone</button-toggle>
        <button-toggle :is-active="activeType==='RAL'" @click="activeType = 'RAL'">RAL</button-toggle>
        <button-toggle :is-active="activeType==='Oracal'" @click="activeType = 'Oracal'">Oracal</button-toggle>
      </div>
    </div>

    <div class="content">
      <div class="page__min-container">
        <div class="row">
          <div class="col">
            <color-item :item="colors[0]" :active-type="activeType"></color-item>
          </div>

          <div class="row-2">
            <div class="col">
              <color-item :item="colors[1]" :active-type="activeType"></color-item>
            </div>
            <div class="col">
              <color-item :item="colors[2]" :active-type="activeType"></color-item>
            </div>
          </div>
        </div>

        <div class="text__normal">
          Для печати текста в малых и&nbsp;средних кеглях рекомендуется вместо составного темного цвета Deep Ocean
          использовать черную краску C=0 M=0 Y=0 K=100
        </div>
      </div>

      <div class="page__min-container">
        <div class="text__head">
          Дополнительные цвета
        </div>
        <div class="row">
          <div class="col" v-for="item in colorsSecond" :key="item.name">
            <color-item :item="item" :active-type="activeType"></color-item>
          </div>
        </div>
      </div>
    </div>

    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import ButtonToggle from "../../../components/Elements/ButtonToggle/ButtonToggle"
import ColorItem from "../../../components/Elements/ColorItem/ColorItem"
import BottomNavBlock from "../../../components/Elements/BottomNavBlock/BottomNavBlock"

export default {
  components: {BottomNavBlock, ColorItem, ButtonToggle},
  data() {
    return {
      activeType: "Hex",
      colorMap: {
        "Deep Ocean": {
          "Hex": "041C36",
          "RGB": "4 28 54",
          "CMYK": "64 44 0 88",
          "Pantone": "282C",
          "RAL": "5011",
          "Oracal": "(641) 562"
        },
        "Winter Sea": {
          "Hex": "D5DDE5",
          "RGB": "213 221 228",
          "CMYK": "10 3 1 0",
          "Pantone": "649C",
          "RAL": "7047",
          "Oracal": "N/A"
        },
        "Steel Gray": {
          "Hex": "7A8B9C",
          "RGB": "122 139 156",
          "CMYK": "35 14 11 34",
          "Pantone": "7544C",
          "RAL": "7046",
          "Oracal": "N/A"
        },
        "Azure I": {
          "Hex": "0759EA",
          "RGB": "7 89 234",
          "CMYK": "87 65 0 0",
          "Pantone": "2132C",
          "RAL": "260 40 45",
          "Oracal": "N/A"
        },
        "Azure II": {
          "Hex": "004CD1",
          "RGB": "0 76 209",
          "CMYK": "100 82 0 0",
          "Pantone": "2132C",
          "RAL": "260 40 45",
          "Oracal": "N/A"
        },
        "Aqua I": {
          "Hex": "42E2EA",
          "RGB": "66 226 234",
          "CMYK": "58 0 17 0",
          "Pantone": "N/A",
          "RAL": "N/A",
          "Oracal": "N/A"
        },
        "Aqua II": {
          "Hex": "0BB7D3",
          "RGB": "11 183 211",
          "CMYK": "72 0 17 0",
          "Pantone": "N/A",
          "RAL": "N/A",
          "Oracal": "N/A"
        },
        "Sand I": {
          "Hex": "A97436",
          "RGB": "169 116 54",
          "CMYK": "26 51 83 19",
          "Pantone": "N/A",
          "RAL": "N/A",
          "Oracal": "N/A"
        },
        "Sand II": {
          "Hex": "C98A40",
          "RGB": "201 138 64",
          "CMYK": "18 47 80 7",
          "Pantone": "N/A",
          "RAL": "N/A",
          "Oracal": "N/A"
        },
        "Sand III": {
          "Hex": "E7A659",
          "RGB": "231 166 89",
          "CMYK": "9 40 70 0",
          "Pantone": "N/A",
          "RAL": "N/A",
          "Oracal": "N/A"
        },
        "Coral": {
          "Hex": "E86060",
          "RGB": "232 96 96",
          "CMYK": "0 75 54 0",
          "Pantone": "N/A",
          "RAL": "N/A",
          "Oracal": "N/A"
        }
      },
      colors: [
        {
          bgColor: "#041C36",
          textColor: "#FFFFFF",
          topText: "Heads</br>Text",
          name: "Deep Ocean",
          color: "041C36",
          col: 2,
          big: true
        },
        {
          bgColor: "#D5DDE6",
          textColor: "#505B66",
          topText: "HUD elements",
          name: "Winter Sea",
          color: "D5DDE5"
        },
        {
          bgColor: "#7A8B9C",
          textColor: "#FFFFFF",
          topText: "Text</br>HUD elements",
          name: "Steel Gray",
          color: "7A8B9C"
        }
      ],
      colorsSecond: [
        {
          bgColor: "#0759EA",
          textColor: "#FFFFFF",
          topText: "Heads and text </br>on dark background",
          name: "Azure I",
          color: "0759EA",
          col: 2
        },
        {
          bgColor: "#004CD1",
          textColor: "#FFFFFF",
          topText: "Heads and text</br>on light background",
          name: "Azure II",
          color: "004CD1"
        },
        {
          bgColor: "#42E2EA",
          textColor: "#505B66",
          topText: "HUD elements on medium</br>and dark background",
          name: "Aqua I",
          color: "42E2EA"
        },
        {
          bgColor: "#0BB7D3",
          textColor: "#FFFFFF",
          topText: "HUD elements on light</br>and dark background",
          name: "Aqua II",
          color: "0BB7D3",
          col: 2
        },
        {
          bgColor: "#A97436",
          textColor: "#FFFFFF",
          topText: "On dark background",
          name: "Sand I",
          color: "A97436"
        },
        {
          bgColor: "#C98A40",
          textColor: "#FFFFFF",
          topText: "On medium to light background",
          name: "Sand II",
          color: "C98A40"
        },
        {
          bgColor: "#E7A659",
          textColor: "#FFFFFF",
          topText: "On white background",
          name: "Sand III",
          color: "E7A659"
        },
        {
          bgColor: "#E86060",
          textColor: "#FFFFFF",
          topText: "HUD elements</br>on dark background",
          name: "Coral",
          color: "E86060"
        }
      ],
      windowTop: window.top.scrollY,
      topIndent: false,
      sticky: false,
      bottomNav: {
        pre: {
          url: "/identity/logo",
          text: "Логотип"
        },
        next: {
          url: "/identity/typography",
          text: "Типографика"
        }
      }
    }
  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY
    }
  },
  watch: {
    activeType() {
      this.colors.forEach(item => {
        item.color = this.colorMap[item.name][this.activeType]
      })

      this.colorsSecond.forEach(item => {
        item.color = this.colorMap[item.name][this.activeType]
      })
    },
    windowTop(newValue, oldValue) {
      if (window.innerWidth > 500) {
        this.sticky = newValue > 380
        this.topIndent = newValue <= oldValue
      } else {
        this.sticky = newValue > 245
        this.topIndent = newValue <= oldValue
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll)
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/page-block";

.page {
  margin-top: 88px;
  margin-bottom: 160px;

  &__min-container {
    margin-bottom: 96px;
  }
}

.buttons-block {
  width: 100%;
  background-color: #F2F4F5;
  padding: 10px 0;
  margin-bottom: 87px;

  & button {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.col {
  margin-bottom: 32px;
}

.text {
  &__head {
    font-size: 26px;
    line-height: 40px;
    letter-spacing: 0;
    margin-bottom: 32px;
  }

  &__normal {
    font-size: 16px;
    line-height: 24px;
    max-width: 689px;
    letter-spacing: 0;
    margin-bottom: 0;
  }
}

.sticky {
  position: fixed;
  //top: 80px;
  top: 0;
  //z-index: 10;
  z-index: 2;
  transition: top .2s ease-in-out;
}

.sticky + .content {
  padding-top: 144px;
}

.top-80 {
  top: 80px;
}

@media screen and (min-width: 1456px) {
  .sticky {
    top: 80px;
  }
}

@media screen and (max-width: 1070px) {

  .buttons-block {

    overflow-y: auto;
    padding: 32px 0;

    &__wrapper {
      display: flex;
      flex-wrap: nowrap;
      min-width: 620px;
      width: 100%;
    }

    & button {
      margin-right: 15px;
    }
  }

  .row {
    &-2 {
      width: 100%;
    }
  }

  .col {
    width: 100%;
  }
}

@media screen and (max-width: 1540px) {
  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 263px;
  }
}


@media screen and (max-width: 1320px) {
  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1090px) {
  .page {
    margin-top: 2px;
    padding-top: 18px;
  }
}

@media screen and (max-width: 896px) {
  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    padding-top: 0;
    margin-bottom: 160px;

    &__full-width {
      width: 100%;
    }

    &__min-container {
      margin-bottom: 80px;
    }
  }

  .buttons-block {
    margin-bottom: 32px;
  }

  .sticky + .content {
    padding-top: 134px;
  }
}

@media screen and (max-width: 896px) {
  .top-80 {
    top: 64px;
    margin-top: 1px;
  }
}

</style>